export interface RegistrationForm {
    id: number,
    title: string,
    url: string,
    language: string,
    subTitle: string,
    bookingId: string,
    description: string,
    branding?: Branding,
    speakers: Array<Speaker>,
    fields?: Array<Field>,
    regDates: Array<RegistrationDate>,
    countRegisteredUser: 0,

    phone: boolean,
    phoneRequired: boolean,

    company: boolean,
    companyRequired: boolean,

    comment: boolean,
    commentRequired: boolean,

    partnerId: boolean,
    partnerIdRequired: boolean,

    address: boolean,
    addressRequired: boolean,

    salutation: boolean,
    salutationRequired: boolean,

    vvId: boolean,
    vvIdRequired: boolean,

    allowMultipleDateSelection: boolean
    hideOldDates: boolean

    legal: Legal
    accountCompany?: string;

    doi?: RegistrationDOI;
    customFields: CustomField[]
}

export interface Legal {
    privacyURL: string,
    imprintName: string,
    imprintCompany?: string
}

export interface Speaker {
    id: number,
    name: string,
    filename: string,
    idx: number
}

export interface Field {
    id: number,
    title: string,
    type: 'TEXT_LINE' | 'TEXT_AREA' | 'CHECKBOX',
    required: boolean,
    idx: number
}

export function fieldSort(f1: Field, f2: Field): number {
    if (f1.idx < f2.idx) return -1;
    if (f1.idx > f2.idx) return 1;
    return 0;
}

export interface RegistrationDate {
    id: number,
    title?: string,
    description?: string,
    start: number,
    end: number,
    userLimit: number,
    countdown: number,
    lastRegistration: number,
    countRegisteredUser: number
}

export function dateSort(d1: RegistrationDate, d2: RegistrationDate): number {
    if (d1.start < d2.start) return -1;
    if (d1.start > d2.start) return 1;
    return 0;
}

export interface Branding {
    id: number,
    version: number,
    logoFileExists: boolean
    bannerFileExists: boolean
    title: string,
    countryISO: string;
    data: string;
    privacy?: string;
    footer?: string;
    footerEnabled: boolean;
}


export interface RegistrationDOI {
    type: RegistrationDOIType;
    subject: string;
    text: string;
    replyTo: string;
    mailTextTemplateId: number;
    sendReminderMail: boolean;
    newsletterText?: string;
}

export type RegistrationDOIType = 'CONFERENCE' | 'NEWSLETTER' | 'DISABLED';

export interface CustomField {
    id: number;
    index: number;
    global: boolean;
    title: string;
    regex?: string;
    description: string;
    ignoreCase: string;
    maxLength?: number;
    required: boolean;
}
