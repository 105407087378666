

























































import {Component, Vue} from 'vue-property-decorator';
import Logo from "@/components/Logo.vue";
import ImprintBlock from "@/components/ImprintBlock.vue";
import Banner from "@/components/Banner.vue";
import {RegistrationForm} from "@/models/RegistrationForm";

@Component({
  components: {Banner, ImprintBlock, Logo}
})
export default class Imprint extends Vue {

  get form(): RegistrationForm | undefined {
    return this.$store.getters.form;
  }

  get imprintURL(): string {
    return location.href;
  }

  get imprintDomain(): string {
    return location.host;
  }

}
