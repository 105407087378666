




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { format, Locale } from 'date-fns';
import {DateWithCheck} from "@/models/UserData";
import RegistrationService from '@/service/RegistrationService';
import {default as localeEN} from "date-fns/locale/en-US";
import {default as localeDE} from "date-fns/locale/de";


@Component
export default class DateCard extends Vue {
  @Prop() private date!: DateWithCheck;
  @Prop() private locale!: Locale;
  @Prop() private index!: number;
  @Prop() private multipleSelection!: boolean;
  @Prop() private state!: boolean | null; // validation state

  get status(): "BookedOut" | "Past" | "Active" {
    return RegistrationService.getDateStatus(this.date.date);
  }

  get year(): string { return format(new Date(this.date.date.start), "y", { locale: this.locale }); }
  get weekDay(): string { return format(new Date(this.date.date.start), "EEEE", { locale: this.locale }); }
  get monthDay(): string { return format(new Date(this.date.date.start), "d", { locale: this.locale }); }
  get month(): string { return format(new Date(this.date.date.start), "LLL", { locale: this.locale }); }
  get time(): string {
    // Hide timezone if regForm language is DE
    const fmt = 'p' + (this.locale === localeEN ? ', z' : '');
    return format(new Date(this.date.date.start), fmt, { locale: this.locale })
        + (this.locale === localeDE ? ' Uhr' : '');
  }
  get dateTime(): string { return format(new Date(this.date.date.start), "Pp, z", { locale: this.locale }); }

  get showSeats(): boolean { return this.seatsLimited && this.availableSeats <= this.date.date.countdown }
  get seatsLimited(): boolean { return this.date.date.userLimit != -1 }
  get availableSeats(): number { return RegistrationService.getDateAvailableSeats(this.date.date); }

}
