import VueI18n from 'vue-i18n';
import Vue from 'vue';

const messages = {
  en: {
    error: 'Error',
    bannedTitle: 'Important Notice',
    notFound: {
      title: 'Not found',
      desc: 'The registration form was not found or is no longer valid.'
    },
    confirmed: {
      title: 'You have successfully registered',
      desc: 'You will shortly receive an email with further information.',
      thanks: 'Thank you for your confirmation'
    },
    form: {
      dates: 'List of dates',
      date: 'Date',
      dateRequired: 'Select a date',
      fieldRequired: 'Additional field is required',
      fieldBooleanRequired: "Please mark the required checkboxes",
      privacyRequired: 'Please agree to the privacy notice',
      multipleDateSelection: '(multiple selection allowed)',
      userData: 'Personal data',
      comment: 'Comment',
      commentRequired: 'Comment is required',
      partnerId: 'Partner-ID',
      partnerIdRequired: 'Partner-ID is required',
      salutation: 'Salutation',
      salutationRequired: 'Provide your salutation',
      salutations: {
        male: 'Mr',
        female: 'Ms / Mrs',
        divers: 'Mx'
      },
      firstname: 'First Name',
      firstnameRequired: 'First Name is required',
      lastname: 'Last Name',
      lastnameRequired: 'Last Name is required',
      street: 'Street and house number',
      streetTT: 'Street and house number (i.e.: Kasernenstr. 23)',
      addressStreetRequired: 'Street is required',
      city: 'Zip code and city',
      cityTT: 'Zip code and city (i.e. 40213 Düsseldorf)',
      addressCityRequired: 'City is required',
      addressCityInvalid: 'Provide postcode and city in correct format (i.e. 40213 Düsseldorf)',
      company: 'Company',
      companyRequired: 'Company is required',
      email: 'E-Mail',
      emailRequired: 'E-Mail is required',
      emailFormat: 'E-Mail is invalid',
      phoneShort: 'Phone number',
      phone: 'Area code and number (e.g. 211 54079704)',
      phoneRequired: 'Phone number is required',
      phoneFormat: 'Phone number is invalid',
      addField: 'Additional information',
      submit: 'Send form',
      submitChange: 'Save changes',
      deleteRegistration: 'Delete my registration',
      privacy: 'data protection declaration',
      vvId: 'gut beraten ID (VV-ID)',
      vvIdHint: 'gut beraten ID / VV-ID (e.g.: 20140417-123456-22)',
      vvIdShort: 'VV-ID',
      vvIdRequired: 'VV-ID is required',
      vvIdInvalidFormat: 'VV-ID has to match this pattern: YYYYMMDD-XXXXXX-XX',
      vvIdInvalidExample: 'VV-ID ist not valid',
      requiredHint: 'All fields marked with an asterisk (*) are mandatory',
      customFieldRequired: '{name} is required',
      customFieldInvalid: '{name} does not correspond to the specified format. Hover your mouse over the input field to see filling instructions.',
      customFieldTooLong: 'Input of {name} is too long',
    },
    success: {
      congrats: 'Congratulations',
      congrats_line_1: 'You have successfully registered.',
      congrats_line_2: 'You will receive an email with more information shortly.',
      confirm_line_1: 'Confirm registration',
      confirm_line_2: 'Please confirm your registration in the email you received.',
      dates: 'Your dates',
      data: 'Your personal data',
      additionalData: 'Your additional data',
      changeData: 'Edit your registration',
    },
    updated: {
      congrats: 'Congratulations',
      congrats_line_1: 'You have successfully updated your registration for the event.',
      congrats_line_2: '',
      confirm_line_1: 'Confirm registration',
      confirm_line_2: 'Please confirm your registration in the email you received.'
    },
    date: {
      bookedOut: 'Booked out',
      past: 'Closed',
      seatsAvailable: 'seats available'
    },
    confirmDelete: 'Do you really want to delete your registration?',
    deleted: {
      title: 'Deleted',
      desc: 'Your registration was completely deleted.'
    },
    backToForm: 'Back to registration form',
    back: 'Back',
    alreadyRegistered: 'You have already registered for this date with your telephone number or e-mail address. A double registration is not possible.',
    banned: 'Registration with the e-mail address or telephone number you entered is currently not possible. Please contact the organizer for more information.',
    banned2: 'In some conferences mobile phones are disabled by default. In that case please call from your landline phone.',
    selectAll: 'select all',
    privacyPrefix: 'Information on the processing of your personal data can be found in the ',
    privacy: 'data protection declaration',
    privacyHeading: 'Hinweis zum Datenschutz:',
    privacy1: 'Your data sent with the registration will be processed by',
    privacy2: ' {company} and ',
    privacy3: 'the service provider CSN. Communication Service Network GmbH to conduct the webinar.',
    imprint: {
      imprint: 'Imprint',
      title: 'Imprint for the registration form',
      domain: 'The domain {domain} is operated by',
      phone: 'Phone: ',
      fax: 'Fax: ',
      mail: 'E-mail: ',
      site: 'Internet: ',
      director: 'Authorized Managing Director: ',
      regCourt: 'Register court: ',
      regNumber: 'Register number: ',
      taxNumber: 'Sales tax identification number according to § 27 a sales tax law: ',
      personInCharge: 'Responsible for the content of this form according to § 18 Abs. 2 MStV',
      contactInfo: 'Contact details are available to be requested from the CSN. GmbH',
      disclaimer: 'Disclaimer',
      disclaimerText: 'Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.',
      dataProtection: 'Note on data protection',
      dataProtectionText: 'Your data sent with the registration will be processed by the {company} and the service provider CSN. Communication Service Network GmbH processed to conduct the webinar.',
      dataProtectionPersonText: 'Your data sent with the registration will be processed by {company} and the service provider CSN. Communication Service Network GmbH processed to conduct the webinar.'
    },
  },
  de: {
    error: 'Fehler',
    bannedTitle: 'Wichtiger Hinweis',
    notFound: {
      title: 'Nicht gefunden',
      desc: 'Das Anmeldeformular wurde nicht gefunden oder ist nicht mehr gültig.'
    },
    confirmed: {
      title: 'Sie haben sich erfolgreich angemeldet',
      desc: 'Sie erhalten in Kürze eine E-Mail mit weiteren Informationen.',
      thanks: 'Vielen Dank für Ihre Bestätigung'
    },
    form: {
      dates: 'Übersicht der Termine',
      date: 'Termin',
      dateRequired: 'Wählen Sie einen Termin',
      fieldRequired: 'Zusatzfeld ist ein Pflichtfeld',
      fieldBooleanRequired: "Bestätigen Sie die notwendigen Checkboxen",
      privacyRequired: 'Sie müssen den datenschutzrechtlichen Hinweisen zustimmen',
      multipleDateSelection: '(Mehrfachauswahl erlaubt)',
      userData: 'Persönliche Daten',
      comment: 'Bemerkungen',
      commentRequired: 'Bemerkungen ist ein Pflichtfeld',
      partnerId: 'Partner-ID',
      partnerIdRequired: 'Partner-ID ist ein Pflichtfeld',
      salutation: 'Anrede',
      salutationRequired: 'Geben Sie Ihre Anrede  an',
      salutations: {
        male: 'Herr',
        female: 'Frau',
        divers: 'Divers'
      },
      vvId: 'gut beraten ID (VV-ID)',
      vvIdHint: 'gut beraten ID / VV-ID (z.B.: 20140417-123456-22)',
      vvIdShort: 'VV-ID',
      vvIdRequired: 'gut beraten ID (VV-ID) ist Pflichtfeld',
      vvIdInvalidFormat: 'gut beraten ID (VV-ID) muss folgendes Format erfüllen: JJJJMMTT-XXXXXX-XX',
      vvIdInvalidExample: 'gut beraten ID (VV-ID) ist ungültig',
      firstname: 'Vorname',
      firstnameRequired: 'Vorname ist ein Pflichtfeld',
      lastname: 'Nachname',
      lastnameRequired: 'Nachname ist ein Pflichtfeld',
      street: 'Straße und Hausnummer',
      streetTT: 'Straße und Hausnummer (z.B: Kasernenstr. 23)',
      addressStreetRequired: 'Straße ist ein Pflichtfeld',
      city: 'PLZ und Ort',
      cityTT: 'PLZ und Ort (z.B. 40213 Düsseldorf)',
      addressCityRequired: 'Ort ist ein Pflichtfeld',
      addressCityInvalid: 'Geben Sie PLZ und Ort korrekt an (z.B. 40213 Düsseldorf)',
      company: 'Firma',
      companyRequired: 'Firma ist ein Pflichtfeld',
      email: 'E-Mail-Adresse',
      emailRequired: 'E-Mail-Adresse ist ein Pflichtfeld',
      emailFormat: 'E-Mail-Adresse ist ungültig',
      phoneShort: 'Telefonnummer',
      phone: 'Ortsvorwahl und Rufnummer (z.B.: 211 54079704)',
      phoneRequired: 'Telefonnummer ist ein Pflichtfeld',
      phoneFormat: 'Telefonnummer ist ungültig',
      addField: 'Weitere Angaben',
      submit: 'Formular absenden',
      submitChange: 'Änderungen speichern',
      deleteRegistration: 'Meine Anmeldung löschen',
      privacy: 'Datenschutzerklärung',
      requiredHint: '* Die mit einem Stern gekennzeichneten Felder sind Pflichtfelder',
      customFieldRequired: '{name} ist ein Pflichtfeld',
      customFieldInvalid: '{name} entspricht nicht dem vorgegebenem Format. Fahren Sie mit der Maus über das Eingabefeld, um Ausfüllhinweise angezeigt zu bekommen.',
      customFieldTooLong: 'Die Eingabe von {name} ist zu lang',
    },
    success: {
      congrats: 'Herzlichen Glückwunsch',
      congrats_line_1: 'Sie haben sich erfolgreich angemeldet.',
      congrats_line_2: 'Sie erhalten in Kürze eine E-Mail mit weiteren Informationen.',
      confirm_line_1: 'Anmeldung bestätigen',
      confirm_line_2: 'Bitte bestätigen Sie Ihre Anmeldung in der erhaltenen E-Mail.',
      dates: 'Ihre Termine',
      data: 'Ihre persönlichen Daten',
      additionalData: 'Ihre Zusatzangaben',
      changeData: 'Anmeldedaten ändern'
    },
    updated: {
      congrats: 'Herzlichen Glückwunsch',
      congrats_line_1: 'Sie haben Ihre Registrierung zur Veranstaltung { title } erfolgreich geändert.',
      congrats_line_2: '',
      confirm_line_1: 'Anmeldung bestätigen',
      confirm_line_2: 'Bitte bestätigen Sie Ihre Anmeldung in der erhaltenen E-Mail.',
    },
    date: {
      bookedOut: 'Ausgebucht',
      past: 'Anmeldefrist abgelaufen',
      seatsAvailable: 'Plätze frei'
    },
    confirmDelete: 'Möchten Sie ihre Anmeldung wirklich löschen?',
    deleted: {
      title: 'Gelöscht',
      desc: 'Ihre Anmeldung wurde komplett gelöscht.'
    },
    backToForm: 'Zurück zum Anmeldeformular',
    back: 'Zurück',
    imprint: {
      imprint: 'Impressum',
      title: 'Impressum für das Anmeldeformular',
      domain: 'Die Domain {domain} wird betrieben von',
      phone: 'Telefon: ',
      fax: 'Telefax: ',
      mail: 'E-Mail: ',
      site: 'Internet: ',
      director: 'Vertretungsberechtigter Geschäftsführer: ',
      regCourt: 'Registergericht: ',
      regNumber: 'Registernummer: ',
      taxNumber: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: ',
      personInCharge: 'Inhaltlich Verantwortlicher für dieses Formular gemäß § 18 Abs. 2 MStV',
      contactInfo: 'Kontaktdaten auf Anfrage bei der CSN. GmbH erhältlich',
      disclaimer: 'Haftungshinweis',
      disclaimerText: 'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
      dataProtection: 'Hinweis zum Datenschutz',
      dataProtectionText: 'Ihre mit der Anmeldung übersandten Daten werden durch die {company} und den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.',
      dataProtectionPersonText: 'Ihre mit der Anmeldung übersandten Daten werden durch {company} und den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.'
    },
    alreadyRegistered: 'Sie haben sich bereits zu diesem Termin mit Ihrer Telefonnummer oder E-Mail-Adresse angemeldet. Eine Doppelregistrierung ist nicht möglich.',
    banned: 'Eine Registrierung mit der von Ihnen eingegebenen Rufnummer oder E-Mail-Adresse ist nicht möglich. Bitte prüfen Sie diesbezüglich die Hinweise im Anmeldeformular oder wenden Sie sich an den Veranstalter.',
    banned2: 'In einigen Veranstaltungen sind Mobilfunknummern gesperrt. Weichen Sie in diesem Fall bitte auf eine Festnetznummer aus.',
    selectAll: 'Alle auswählen',
    privacyPrefix: 'Hinweise zur Verarbeitung Ihrer personenbezogenen Daten erhalten Sie in der ',
    privacy: 'Datenschutzerklärung',
    privacyHeading: 'Hinweis zum Datenschutz:',
    privacy1: 'Ihre mit der Anmeldung übersandten Daten werden durch',
    privacy2: ' die {company} und',
    privacy3: 'den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.'
  }
}

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
  locale: navigator.languages[0], // set locale
  messages, // set locale messages
})
