



















import { Component, Prop, Vue } from 'vue-property-decorator';
import {RegistrationForm} from "@/models/RegistrationForm";

@Component
export default class Banner extends Vue {
  @Prop() private title!: string;
  @Prop() private subTitle!: string;
  @Prop() private useBottomBorderRadius!: boolean;

  get imageUrl(): string | undefined {
    return this.$store.getters.bannerUrl;
  }

  get form(): RegistrationForm | undefined {
    return this.$store.getters.form;
  }

  get showStandardImage(): boolean {
    return this.form?.branding === undefined;
  }

}
