import axios from "axios";

export interface ValidatedPhoneNumber {
  originalNumber: string;
  validNumber: boolean;
  international: string;
  regionCode: string
  type: string;
}

export default {

  validatePhoneNumber(number: string, countryCode: string): Promise<ValidatedPhoneNumber> {
    return axios.get(`/validator/phonenumber`, {params: {number, region: countryCode}}).then(response => response.data)
  }

}