








import { Component, Vue } from 'vue-property-decorator';
import {RegistrationForm, Speaker} from "@/models/RegistrationForm";
import SpeakerCard from "@/components/SpeakerCard.vue";

@Component({
  components: {
    SpeakerCard
  },
})
export default class SpeakerBlock extends Vue {

  get form(): RegistrationForm | undefined {
    return this.$store.getters.form;
  }

  get sortedSpeaker():Array<Speaker> {
    if (this.form === undefined) return [];
    return this.form.speakers.sort((s1:Speaker, s2:Speaker) => {
      if (s1.idx < s2.idx) return -1;
      if (s1.idx > s2.idx) return 1;
      return 0;
    });
  }
}
