import {CustomField, Field, RegistrationDate} from "@/models/RegistrationForm";

export interface UserData {
  id: number,
  dateCreated: number,
  dateDoi?: number,
  key: string,
  firstname: string,
  lastname: string,
  email: string,
  phone: string | undefined,
  company: string | undefined,
  comment: string | undefined,
  partnerId: string | undefined,
  addressStreet: string | undefined,
  addressCity: string | undefined
  salutation: Salutation | undefined,
  vvId: string | undefined,
  dateIds: Array<number>,
  newsletterActive: boolean | undefined,
  values: Array<FieldValue>;
  customValues: Array<FieldValue>,
  customUrlParams: Array<{key: string, value: string}>
}

export enum Salutation {
  NONE = '',
  MALE = 'male',
  FEMALE = 'female',
  DIVERS = 'divers'
}

export interface FieldValue {
  fieldId: number,
  value: string
}

export interface DateWithCheck {
  date: RegistrationDate,
  check: boolean
}

export interface FieldWithValueString {
  field: Field,
  value: string
}

export interface FieldWithValueBoolean {
  field: Field,
  value: boolean
}

export interface CustomFieldWithValueString {
  field: CustomField;
  value: string;
}
