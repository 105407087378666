










































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import {RegistrationForm} from "@/models/RegistrationForm";
import {UserData} from "@/models/UserData";
import {UserDataValidation} from "@/models/Validation";
import {Country, countryCodes, countryCodes as countries} from "@/models/CountryCodes";
import PhoneValidationService from "@/service/PhoneValidationService";

@Component
export default class User extends Vue {
  @Prop({ default: false }) private showValidation!: boolean;

  @Prop() private validation!: UserDataValidation;

  countryCodes = countries;

  currentCountry:Country | undefined = countryCodes[0];
  currentPhone = "";

  phoneInvalid = false;

  mounted(): void {
    this.initPhone();
  }

  get form(): RegistrationForm | undefined {
    return this.$store.getters.form;
  }
  get userData(): UserData {
    return this.$store.getters.userData;
  }

  @Watch('userData')
  onPropertyChanged():void {
    this.initPhone();
  }

  initPhone(): void {
    // const defaultCountry = this.form.branding?.countryISO || "DE";
    // if (!this.userData.phone) {
    //   this.currentCountry = this.countryCodes.find(country => country.isoCode === defaultCountry);
    // } else {
    //   // Split international phone number in country code and local number
    //   PhoneValidationService.validatePhoneNumber(this.userData.phone, "DE").then(validatedNumber => {
    //     this.currentCountry = this.countryCodes.find(country => country.isoCode === validatedNumber.regionCode);
    //     if (this.currentCountry) {
    //       this.currentPhone = this.userData.phone.slice(this.currentCountry.dialCode.length).trim();
    //     }
    //   })
    // }
  }

  async validate(): Promise<void> {
    this.validation.userMessages.splice(0, this.validation.userMessages.length);
    this.validation.userValid = true;

    if (!this.userData.firstname) {
      this.validation.userMessages.push(this.$t("form.firstnameRequired").toString());
      this.validation.userValid = false;
    }

    if (!this.userData.lastname) {
      this.validation.userMessages.push(this.$t("form.lastnameRequired").toString());
      this.validation.userValid = false;
    }

    if (!this.userData.email) {
      this.validation.userMessages.push(this.$t("form.emailRequired").toString());
      this.validation.userValid = false;
    } else if (!this.emailFormat) {
      this.validation.userMessages.push(this.$t("form.emailFormat").toString());
      this.validation.userValid = false;
    }

    if (!this.userData.phone) {
      this.validation.userMessages.push(this.$t("form.phoneRequired").toString());
      this.validation.userValid = false;
    }

    if (this.currentCountry?.isoCode) {
      await PhoneValidationService.validatePhoneNumber(this.currentPhone, this.currentCountry.isoCode).then(validatedNumber => {
        this.phoneInvalid = !validatedNumber.validNumber;
        if (!validatedNumber.validNumber) {
          this.validation.userMessages.push(this.$t("form.phoneFormat").toString());
          this.validation.userValid = false;
        } else {
          this.userData.phone = validatedNumber.international;
        }
      })
    }
  }

  get emailFormat(): boolean {
    return this.validateEmail(this.userData.email);
  }

  validateEmail(mail: string): boolean {
    // https://www.w3resource.com/javascript/form/email-validation.php
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  label(name: string, required = true): string {
    return this.$t(`form.${name}`) + (required ? '*' : '')
  }
}
