import {RegistrationForm} from "@/models/RegistrationForm";

export function salutationString(salutation: string | undefined, regForm: RegistrationForm, prefix = true): string {
    if (!salutation) return '-';
    if (regForm.language.toLowerCase() === 'de') {
        switch (salutation.toLowerCase()) {
            case 'male':
            case 'mr':
                return (prefix ? 'Sehr geehrter ' : '') + 'Herr';
            case 'female':
            case 'ms':
            case 'mrs':
                return (prefix ? 'Sehr geehrte ' : '') + 'Frau';
            default:
                return prefix ? 'Divers' : 'Sehr geehrte*r';
        }
    } else {
        switch (salutation.toLowerCase()) {
            case 'male':
            case 'mr':
                return (prefix ? 'Dear ' : '') + 'Mr';
            case 'female':
            case 'ms':
            case 'mrs':
                return (prefix ? 'Dear ' : '') + 'Mrs';
            default:
                return prefix ? 'Diverse' : 'Dear';
        }
    }
}