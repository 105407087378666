







import {Component, Vue} from 'vue-property-decorator';
import {LogoPosition} from "@/store/form";

@Component
export default class Logo extends Vue {

  get logoUrl(): undefined | string {
    return this.$store.getters.logoUrl;
  }

  get logoPosition(): LogoPosition {
    return this.$store.getters.logoPosition;
  }

}
