











import { Component, Prop, Vue } from 'vue-property-decorator';
import {Speaker} from "@/models/RegistrationForm";
import RegistrationService from "@/service/RegistrationService";

@Component
export default class SpeakerCard extends Vue {
  @Prop() private speaker!: Speaker;
  @Prop() private formId!: number;

  get imageUrl(): string {
    return `${RegistrationService.getUrl()}/registration/${this.formId}/speaker/${this.speaker.id}/photo`
  }
}
